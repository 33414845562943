.locale_switch {
  display: flex;
  background: #1a245780;
  border-radius: 30px;
  width: min-content;
  margin: 2px 10px;
}

.locale_switch_element {
  border-radius: 30px;
  padding: 6px 16px;
  box-sizing: border-box;
  transition: 0.3s;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.locale_switch_element.active {
  background: rgba(26,36,87,1);
}

.locale_switch_element + .locale_switch_element {
  margin-left: -8px;
}

.should_translate {
  background: #ebebff;
  border-radius: 8px;
  padding: 4px;
}

.ce-block__content {
  max-width: 100%;
}

.ce-toolbar__content {
  max-width: 100%;
}

h1, h2, h3 {
  font-family: GTEesti !important;
}

h1 {
  color: #323232;
  font-weight: 600 !important;
  font-size: 42px !important;
  line-height: 48px !important;
}

h2 {
  font-weight: 600 !important;
  font-size: 30px !important;
  line-height: 32px !important;
}

h3 {
  font-weight: 600 !important;
}

.cdx-list {
  margin-top: 6px;
  padding: 0;
}

.cdx-list .cdx-list__item {
  position: relative;
  list-style: none;
  padding-left: 22px;
  color: #323232;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  padding-top: 10px;
  width: 100%;
  padding-bottom: 5px;
  margin-top: 0;

}

.cdx-list__item {
  min-height: 42px;
}

.cdx-list .cdx-list__item:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  border: 2px solid #00bb58;
  left: 0;
  top: 16px;
  border-radius: 50%;
  background-color: transparent;
  box-sizing: border-box;
}

.ce-paragraph {
  margin-top: 18px;
  font-size: 18px;
  line-height: 26px !important;
  padding: 0;
}

*[contenteditable="true"]:focus-visible {
  outline: -webkit-focus-ring-color outset 2px;
  border-radius: 2px;
}

br {
  content: '';
  display: block;
  margin: 8px 0;
}