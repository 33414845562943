.hotelcard_hotel_wrapper {
  max-width: 100%;
}

.hotelcard_hotel_topcontainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.hotelcard_hotel_top_leftcontainer {

}

.hotelcard_hotel__caption {
  width: 100%;
  margin-bottom: 4px;
  margin-top: 16px;
}

.hotelcard_hotel__caption[contentEditable="true"][data-placeholder]::before {
  position: absolute !important;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  display: none;
}

.hotelcard_hotel__caption[contentEditable="true"][data-placeholder]:empty::before {
  display: block;
}
.hotelcard_hotel__caption[contentEditable="true"][data-placeholder]:empty:focus::before {
  display: none;
}

.hotelcard_hotel_top_rightcontainer {
  min-width: 90px;
  max-width: 90px;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.hotelcard_hotel_name {
  font-size: 30px;
  line-height: 32px;
}

.hotelcard_hotel_location {
  margin-top: 12px;
  font-size: 18px;
  line-height: 21px;
}

.hotelcard_hotel_rating {
  color: #6dd098;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e4f9ee;
  border-radius: 16px;
  padding: 12px 16px 15px;
  font-size: 20px;
  line-height: 23px;
  font-weight: 700;
}

.hotelcard_hotel_rating:before {
  content: '';  
  min-width: 20px;
  margin-right: 8px;
  min-height: 18px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNCAxMyIgZmlsbD0iIzZkZDA5OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0wIDQuNDAyNDdWMTEuNDAyNUMwIDExLjg4NTcgMC4zOTE3MzEgMTIuMjc3NSAwLjg3NQogICAgICAxMi4yNzc1SDMuNVYzLjUyNzQ3SDAuODc1QzAuMzkxNzMxIDMuNTI3NDcgMCAzLjkxOTIgMCA0LjQwMjQ3WiI+PC9wYXRoPgoKICAgIDxwYXRoIGQ9Ik0xMi4zODQyIDQuNDAyMjhIOS43OTkzMkM5LjU3MzMxIDQuNDAyMjggOS40NjAwOSA0LjI1MTQ3CiAgICAgIDkuNDIyMDYgNC4xODY1MkM5LjM4NDA0IDQuMTIyMDEgOS4zMDcxMyAzLjk0OTgzIDkuNDE2NTEKICAgICAgMy43NTI0NEwxMC4zMjc4IDIuMTExMzlDMTAuNTI3OCAxLjc1MjA3IDEwLjU0ODMgMS4zMjk1MwogICAgICAxMC4zODQ2IDAuOTUyMjY2QzEwLjIyMSAwLjU3NDU4MSA5Ljg5ODAxIDAuMzAwNzE2IDkuNDk4NTMKICAgICAgMC4yMDExNjhMOC44NTU5NSAwLjA0MDUyMzRDOC42OTk1OCAwLjAwMTIxNjc3IDguNTMzODEKICAgICAgMC4wNTEyMDQ2IDguNDI0ODYgMC4xNzIxMTZMNC45MzU5NyA0LjA0ODA5QzQuNTc0MSA0LjQ1MDk5CiAgICAgIDQuMzc1IDQuOTcwNTIgNC4zNzUgNS41MTE0MVYxMC4wODk4QzQuMzc1IDExLjI5NTkgNS4zNTYzOAogICAgICAxMi4yNzczIDYuNTYyNSAxMi4yNzczTDEwLjkxNDkgMTIuMjc2OEMxMS44OTc1IDEyLjI3NjgKICAgICAgMTIuNzY1MyAxMS42MTUgMTMuMDI0NiAxMC42Njc4TDEzLjk1ODYgNi4zODE3QzEzLjk4NTkgNi4yNjI1CiAgICAgIDE0IDYuMTQwMzEgMTQgNi4wMTgxMkMxNCA1LjEyNzMxIDEzLjI3NSA0LjQwMjI3IDEyLjM4NDEKICAgICAgNC40MDIyN0wxMi4zODQyIDQuNDAyMjhaIj48L3BhdGg+CiAgPC9zdmc+);
}

.hotelcard_hotel_reviews{
  margin-top: 8px;
  font-size: 16px;
  width: 100%;
  line-height: 19px;
  color: #3582db;
  font-family: GTEesti;
}

.hotelcard_hotel_amenities {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.hotelcard_hotel_amenity {
  margin-bottom: 8px;
  margin-right: 8px;
  background: #f5f5f7;
  border-radius: 32px;
  font-size: 16px;
  line-height: 19px;
  padding: 4px 12px;
  white-space: nowrap;
  color: #999;
}

.hotelcard_hotel_image {
  width: 100%;
  border-radius: 9px;
}

.hotelcard_hotel_bottom {
  width: 100%;
  margin-top: 20px;
  min-height: 70px;
}

.hotelcard_hotel_description {
  font-size: 18px;
  line-height: 26px;
  display: inline;
  text-indent: 16px;
}

.hotelcard_hotel_description:empty:before {
  content: 'Введите описание';
}

.hotelcard_hotel_description br{
  content: '';
  margin: 8px 0;
  white-space: pre;
  display: block;
}

.hotelcard_hotel_pricecontainer {
  min-width: 220px;
  max-width: 220px;
  margin-left: 27px;
  border: 1px solid #f1e5dd;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;
  float: right;
  margin-bottom: 30px;
}

.hotelcard_hotel_billing {
  margin-left: 6px;
  white-space: nowrap;
  line-height: 17px;
  font-size: 15px;
}

.hotelcard_hotel_billing:empty:before {
  content: 'Введите период';
}

.hotelcard_hotel_price {
  white-space: nowrap;
  line-height: 25px;
  font-size: 20px;
  font-weight: 700;
}

.hotelcard_hotel_price_button {

}