.regioncard_hotel_wrapper {
  width: 100%;
  background: linear-gradient(89.47deg, #D3D9FC 29.49%, #F5E3FF 99.5%);
  border-radius: 6px;
  height: 144px;
  padding: 0 44px 0 57px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.regioncard_left_content {
  display: flex;
  align-items: center;
}

.regioncard_right_content {

}

.regioncard_left_icon {

}

.regioncard_right_icon {

}

.regioncard_text_content {
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: #323232;
  max-width: 400px;
  margin-left: 28px;
}

.regioncard_common_text {
  display: inline;
  font-family: 'GTEesti';
}

.regioncard_focus_text {
  color: #3582DB;
  display: inline;
  font-family: 'GTEesti';
  margin-left: 8px;
}

.regioncard_count_text {
  font-family: 'GTEesti';
  display: inline;
  margin-left: 8px;
}