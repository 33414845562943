.comment {
  margin-top: 68px;
}

.comment_title {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 32px;
  letter-spacing: 0;
  line-height: 36px;
  margin: 0;
  white-space: break-spaces;
}

.comment_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 24px;
}

.comment_slider_container {
  display: flex;
  width: 45%;
  margin-bottom: 20px;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px 12px;
}

.comment_slider_container input {
  margin-top: 2px;
  cursor: pointer;
}

.comment_value {
}

.comment_label {
  font-size: 20px;
  display: block;
  min-width: 150px;
}

.comment_name_container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.comment_name_container .comment_label {
  min-width: auto;
}

.comment_best_title {
  font-weight: 600;
  margin-top: 12px;
  font-size: 18px;
}

.comment_header_container {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.comment_total {
  margin-left: 8px;
  min-width: 20px;
  color: #3582db;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
}

.comment_name_container .comment_slider_container {
  margin-bottom: 0;
}

.comment_name {
  font-size: 20px;
  font-weight: 600;
  min-width: 100px;
  line-height: 24px;
}

.comment_rating_slider {

}

.comment_text {
  font-size: 16px;
  margin-top: 12px;
  min-width: 100px;
}

.error {
  border: 2px solid tomato !important;
}
