.map {
  margin-top: 68px;
}

.map_title {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 32px;
  letter-spacing: 0;
  line-height: 36px;
  margin: 0;
}

.map_description {
  margin-top: 20px;
  color: #323232;
  font-size: 16px;
  line-height: 18px;
  padding: 4px;
}

.map_coordinates {
  margin-top: 16px;
}

.map_coordinates input {
  height: 30px;
  padding-left: 20px;
  font-size: 16px;
  border-radius: 8px;
  outline: 0;
  border: 2px solid #c0c0c0;
}

.map_coordinates input + input {
  margin-left: 20px;
}