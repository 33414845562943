.gallery-img {
  width: 180px;
  margin-bottom: 30px;
  border-radius: 10px;
}


.gallery-tool__image {
  display: flex;
  flex-wrap: wrap;
}

.gallery-img-container {
  position: relative;
  width: min-content;
  margin-right: 20px;
}

.gallery-img-container .gallery-img-remove {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background: rgba(0,0,0,0.4);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
}

.gallery-img-container .gallery-img-remove:before, .gallery-img-container .gallery-img-remove:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  background: rgba(255,255,255,0.5);
  transition: 0.2s;
}

.gallery-img-remove:hover:after, .gallery-img-remove:hover:before {
  background: white;
}

.gallery-img-container .gallery-img-remove:before {
  transform: rotate(45deg);
}

.gallery-img-container .gallery-img-remove:after {
  transform: rotate(-45deg);
}