.hotelcard {
  margin-top: 68px;
}

.hotelcard_title {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 36px;
  margin: 0;
}

.hotelcard_select_element_desc {
  color: #999999;
  line-height: 16px;
}

.hotelcard_description {
  margin-top: 20px;
  color: #323232;
  font-size: 16px;
  line-height: 18px;
  padding: 4px;
}

.hotelcard_coordinates {
  margin-top: 16px;
}

.hotelcard_input {
  height: 30px;
  padding-left: 20px;
  font-size: 16px;
  border-radius: 8px;
  outline: 0;
  border: 2px solid #c0c0c0;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
}

.hotelcard_select_container {

}

.hotelcard_select_list {
  width: 100%;
  border: 2px solid #c0c0c04d;
  border-radius: 8px;
  margin-top: 12px;
  box-sizing: border-box;
  max-height: 200px;
  min-height: 200px;
  overflow: auto;
  padding: 10px 0;
}

.hotelcard_select_element {
  padding: 6px 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.hotelcard_select_element:hover {
  background: #eeecec;
}

.hotel_block {
  display: flex;
  flex-direction: column;
  position: relative;
}

.hotelcard_gallery_img_container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.hotelcard_gallery-img {
  width: 100%;
  border-radius: 10px;
}


.hotelcard_gallery_img_remove {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background: rgba(0,0,0,0.4);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
}

.hotelcard_gallery_img_remove:before, .hotelcard_gallery_img_remove:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  background: rgba(255,255,255,0.5);
  transition: 0.2s;
}

.hotelcard_gallery_img_remove:hover:after, .hotelcard_gallery_img_remove:hover:before {
  background: white;
}

.hotelcard_gallery_img_remove:after {
  transform: rotate(-45deg);
}

.hotelcard_gallery_img_remove:before {
  transform: rotate(45deg);
}

.hotelcard_add_image_button {
  margin: 20px 0;
  height: 42px;
  padding: 0 24px;
  font-size: 14px;
  border-radius: 4px;
  color: #FFFFFF;
  background: #52AB18;
  cursor: pointer;
  position: relative;
}

.hotelcard_add_image_button:after {
  content: 'Добавить изображение';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

