.blogcard_hotel_wrapper {
  width: 400px;
  background: #FFEED6;
  border-radius: 4px;
  padding: 21px 31px 24px 26px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blogcard_left_content {
  display: flex;
  align-items: center;
}

.blogcard_left_icon {

}

.blogcard_right_icon {

}

.blogcard_text_content {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #323232;
  margin-left: 15px;
}

.blogcard_common_text {
  display: inline;
  font-family: 'GTEesti';
}

.blogcard_focus_text {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #323232;
  display: inline;
  font-family: 'GTEesti';
  text-decoration: underline;
}

.blogcard_count_text {
  font-family: 'GTEesti';
  display: inline;
  margin-left: 8px;
}