.hotelcardSm_hotel_wrapper {
  max-width: 788px;
  min-width: 650px;
  display: flex;
  align-items: flex-start;
  padding: 24px;
  background: #f5f0ec;
  border-radius: 9px;
  display: flex;
  margin: 10px 0;
}

.hotelcardSm_hotel_image {
  width: 100%;
  border-radius: 9px;
}

.hotelcardSm_hotel_image_container {
  width: 300px;
}

.hotelcardSm_hotel__caption {
  width: 100%;
  margin-bottom: 4px;
  margin-top: 16px;
  font-size: 12px;
}

.hotelcardSm_hotel__caption[contentEditable="true"][data-placeholder]::before {
  position: absolute !important;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  display: none;
  font-size: 12px;
}

.hotelcardSm_hotel__caption[contentEditable="true"][data-placeholder]:empty::before {
  display: block;
}
.hotelcardSm_hotel__caption[contentEditable="true"][data-placeholder]:empty:focus::before {
  display: none;
}

.hotelcardSm_hotel_rightcontainer {
  margin-left: 20px;
  width: 100%;
}

.hotelcardSm_hotel_name {
  font-size: 30px;
  line-height: 32px;
  margin-top: 12px;
  font-family: GTEesti;
  font-size: 22px;
  font-weight: 600;
}

.hotelcardSm_hotel_locationcontainer {
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-family: GTEesti;
}

.hotelcardSm_hotel_rating {
  background: #5bb884;
  color: #fff;
  display: flex;
  padding: 0 8px;
  align-items: center;
  font-family: GTEesti;
  border-radius: 3px;
  height: 21px;
  width: 50px;
  justify-content: center;
}

.hotelcardSm_hotel_rating:before {
  content: '';  
  width: 14px;
  margin-right: 7px;
  height: 13px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNCAxMyIgZmlsbD0iI2ZmZiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCA0LjQwMjQ3VjExLjQwMjVDMCAxMS44ODU3LjM5MTczMSAxMi4yNzc1Ljg3NSAxMi4yNzc1SDMuNVYzLjUyNzQ3SC44NzVDLjM5MTczMSAzLjUyNzQ3IDAgMy45MTkyIDAgNC40MDI0N3pNMTIuMzg0MiA0LjQwMjI4SDkuNzk5MzJDOS41NzMzMSA0LjQwMjI4IDkuNDYwMDkgNC4yNTE0NyA5LjQyMjA2IDQuMTg2NTIgOS4zODQwNCA0LjEyMjAxIDkuMzA3MTMgMy45NDk4MyA5LjQxNjUxIDMuNzUyNDRMMTAuMzI3OCAyLjExMTM5QzEwLjUyNzggMS43NTIwNyAxMC41NDgzIDEuMzI5NTMgMTAuMzg0Ni45NTIyNjYgMTAuMjIxLjU3NDU4MSA5Ljg5ODAxLjMwMDcxNiA5LjQ5ODUzLjIwMTE2OEw4Ljg1NTk1LjA0MDUyMzRDOC42OTk1OC4wMDEyMTY3NyA4LjUzMzgxLjA1MTIwNDYgOC40MjQ4Ni4xNzIxMTZMNC45MzU5NyA0LjA0ODA5QzQuNTc0MSA0LjQ1MDk5IDQuMzc1IDQuOTcwNTIgNC4zNzUgNS41MTE0MVYxMC4wODk4QzQuMzc1IDExLjI5NTkgNS4zNTYzOCAxMi4yNzczIDYuNTYyNSAxMi4yNzczTDEwLjkxNDkgMTIuMjc2OEMxMS44OTc1IDEyLjI3NjggMTIuNzY1MyAxMS42MTUgMTMuMDI0NiAxMC42Njc4TDEzLjk1ODYgNi4zODE3QzEzLjk4NTkgNi4yNjI1IDE0IDYuMTQwMzEgMTQgNi4wMTgxMiAxNCA1LjEyNzMxIDEzLjI3NSA0LjQwMjI3IDEyLjM4NDEgNC40MDIyN0wxMi4zODQyIDQuNDAyMjh6Ii8+PC9zdmc+);
}

.hotelcardSm_hotel_location {
  color: #2e7cd5;
  font-weight: 600;
  margin-left: 12px;
  font-size: 14px;
  font-family: GTEesti;
}

.hotelcardSm_hotel_description {
  margin-top: 20px;
  font-size: 16px;
  line-height: 19px;
}

.hotelcardSm_hotel_description:empty:before {
  content: 'Введите описание';
}

.hotelcardSm_hotel_bottom {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hotelcardSm_hotel_pricecontainer {

}

.hotelcardSm_hotel_billing {
  white-space: nowrap;
  line-height: 17px;
  font-family: GTEesti;
  font-size: 14px;
  font-weight: 600;
}

.hotelcardSm_hotel_billing:empty:before {
  content: 'Введите период';
}

.hotelcardSm_hotel_price {
  white-space: nowrap;
  font-size: 22px;
  font-weight: 700;
  font-family: GTEesti;
}

.hotelcardSm_hotel_price_button {

}