.links {
  margin-top: 68px;
}

.links_title {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0;
}

.links_input_wrapper {
  display: flex;
  align-items: center;
  padding: 3px;
  box-sizing: border-box;
}

.links_checkbox {
  margin-left: 8px;
}

.links_label {
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}

.links_element {
  padding-left: 30px;
  position: relative;
  padding-top: 12px;
  width: 100%;
  padding-bottom: 13px;
  background: #e2e2e2;
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
}

.links_element + .links_element {
  margin-top: 20px;
}

.links_element_text {
  color: #323232;
  font-size: 16px;
  list-style: none;
  letter-spacing: 0;
  line-height: 22px;
  max-width: 80%;
}

.links_element_href {
  color: #2e7cd6;
  cursor: pointer;
  max-width: 80%;
  font-size: 16px;
  list-style: none;
  letter-spacing: 0;
  line-height: 22px;
}

.links_list {
  width: 100%;
  padding-left: 0;
  margin-top: 20px;
  column-count: 1;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  max-width: 80%;
}

.links_description {
  margin-top: 8px;
  font-size: 16px;
  line-height: 22px;
}

.links_element_remove {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  width: 20px;
  height: 20px;
  background: rgba(0,0,0,0.4);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.links_element_remove:before, .links_element_remove:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  background: rgba(255,255,255,0.5);
  transition: 0.2s;
}

.links_element_remove:hover:after, .links_element_remove:hover:before {
  background: white;
}

.links_element_remove:before {
  transform: rotate(45deg);
}

.links_element_remove:after {
  transform: rotate(-45deg);
}

.links_add {
  padding: 13px;
  border-radius: 3px;
  border: 1px solid rgba(201,201,204,.48);
  font-size: 14.9px;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgb(18 30 57 / 4%);
  color: #707684;
  text-align: center;
  cursor: pointer;
  margin-top: 12px;
  box-sizing: border-box;
  max-width: 80%;
}

.links_add:hover {
  background: #fbfcfe;
  box-shadow: 0 1px 3px 0 rgb(18 30 57 / 8%);
}