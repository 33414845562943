.stars {
  margin-top: 68px;
}

.stars_title {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 32px;
  letter-spacing: 0;
  line-height: 36px;
  margin: 0;
  white-space: break-spaces;
}

.stars_container {
  display: flex;
  gap: 50px;
  margin-top: 12px;
}

.stars_radio_wrapper {

}

.stars_label {
  font-size: 20px;
  text-align: center;
  display: block;
}

.stars_radio {
  display: block;
  width: 20px;
  height: 20px;
}

.error {
  border: 2px solid tomato !important;
}
